/* eslint-disable react/prop-types */
import { isBefore, format, startOfDay } from 'date-fns'
import PropTypes from 'prop-types'
import { useState, useMemo } from 'react'
import { SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator'
import { useSelector } from 'react-redux'
import { changeStatus, deleteEvent, copyEvent } from 'api/ApiMain'
import { ReactComponent as IconCopy } from 'assets/images/button/icon_copy.svg'
import { ReactComponent as IconDelete } from 'assets/images/button/icon_delete.svg'
import { ReactComponent as IconEdit } from 'assets/images/button/icon_edit.svg'
import { ReactComponent as IconSetting } from 'assets/images/button/icon_gear.svg'
import { ReactComponent as IconSeal } from 'assets/images/button/icon_seal.svg'
import imgLogo from 'assets/images/img-logo.png'
import { colorObj } from 'assets/styles/Variable/Color'
import withPermission from 'components/hocs/withPermission'
import {
  StyledCheckbox,
  StyledFormatter,
  FormatterFnWrapper,
  StyledFormatterSetting,
  StyledFormatterWay,
  StyledFormatterAmount,
  StyledFormatterInfo,
} from 'components/pages/Gift/ExchangeMainStyle'

import { Row, Col, IconButton, Dropdown, Modal, Select } from 'components/units'
import { COMPONENT_PERMISSIONS, PAGE_PATHS } from 'constant'
import { currency, number } from 'helpers/format'
import { settingPageType, tabStatus } from 'pages/Gift/config'

import { isPermissionAllowed } from 'store/permission/permissionSlice'

const { UPDATE, DELETE, CREATE } = COMPONENT_PERMISSIONS.gift
const PermissionUpdateIcon = withPermission(IconButton, UPDATE)
const PermissionActivateIconButton = withPermission(IconButton, UPDATE)

const tableFnType = {
  view: 'viewBtn',
  edit: 'editBtn',
  upload: 'availBtn',
  takeDown: 'unavailBtn',
  remove: 'deleteBtn',
  copy: 'copyBtn',
  seal: 'sealBtn',
}

const quantityOptions = Array.from({ length: 10 }, (_, i) => ({
  text: i + 1,
  value: i + 1,
}))

function Caption({ paginationProps, apiPayload, setApiPayload }) {
  return (
    <Row className="caption">
      <Col className="label-title">
        <div>
          總項目：
          <span className="paragraph-info">
            {paginationProps.totalSize || 0} 筆
          </span>
        </div>
        <StyledCheckbox
          label="依更新時間排序"
          id="consume-record"
          name="currentShop"
          checked={apiPayload.sf === 'updatedTime'}
          disabled={apiPayload.sf === 'updatedTime'}
          inline
          onChange={() => {
            setApiPayload((prev) => ({
              ...prev,
              sf: 'updatedTime',
              so: 'desc',
            }))
          }}
        />
      </Col>
      <Col className="label-title text-right">
        <span className="white-space-nowrap mr-1">每頁</span>
        <SizePerPageDropdownStandalone
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...paginationProps}
          sizePerPage={`${paginationProps.sizePerPage} 筆`}
        />
      </Col>
    </Row>
  )
}

const formatterType = ({ exchangeWay, receiveWay }) => {
  return (
    <StyledFormatter>
      {exchangeWay && <div>{exchangeWay}</div>}
      {receiveWay && <div>{receiveWay}</div>}
    </StyledFormatter>
  )
}

const formatterInfo = ({ color, customId, imgUrl, name, size }) => {
  const giftImg = imgUrl || imgLogo
  return (
    <StyledFormatterInfo info>
      <div className="info-container">
        <img src={giftImg} alt={`gitf-${customId}`} />
        <div className="info-content">
          <div className="info-title">{name}</div>
          <div className="info-default">
            <div>{customId}</div>
            <div>
              <span>{color}</span>
              <span>{color && size && ' | '}</span>
              <span>{size}</span>
            </div>
          </div>
        </div>
      </div>
    </StyledFormatterInfo>
  )
}

const formatterObject = (cell, row) => {
  if (!Array.isArray(cell)) return null
  return (
    <StyledFormatter>
      {cell.map((item) => (
        <div key={`${row.giftSettingId}-${item}`}>{item}</div>
      ))}
    </StyledFormatter>
  )
}

const formatterDate = (cell) => {
  const [startDate, endDate] = cell
  const isEventPassed = isBefore(new Date(endDate), startOfDay(new Date()))

  const endDateClassName = isEventPassed ? 'dateRange-expired' : ''

  if (endDate !== null) {
    return (
      <StyledFormatter width="100px" color="#000000" date>
        <span>{`${format(new Date(startDate), 'yyyy/MM/dd')} ~ `}</span>
        <span className={endDateClassName}>{`${format(
          new Date(endDate),
          'yyyy/MM/dd'
        )}`}</span>
      </StyledFormatter>
    )
  }
  return (
    <StyledFormatter width="100px" color="#000000" date>{`${format(
      new Date(startDate),
      'yyyy/MM/dd'
    )} 起`}</StyledFormatter>
  )
}

const formatterAmount = ({
  isGiftCodeNotEnough,
  everyMemberOnce,
  exchangedCount,
  inStock,
}) => {
  const inStockQuantity = inStock?.value
  const warn = inStock?.colorRed

  return (
    <StyledFormatterAmount amount>
      <div>
        <span className={`${warn ? 'amount-warn' : ''}`}>
          餘 {number(inStockQuantity)}
        </span>
        <span>，已換 {number(exchangedCount)}</span>
      </div>
      {everyMemberOnce && <div className="amount-once">每人限兌換 1 次</div>}
      {isGiftCodeNotEnough && <div className="amount-warn">序號不足</div>}
    </StyledFormatterAmount>
  )
}

const currencyWithSuffix = (value) => `${currency(value || 0)} 元`
const numberWithSuffix = (value) => `${number(value || 0)} 點`

const formatterWay = ({ onlyPoint, pointMoney, onlyMoney }) => {
  return (
    <StyledFormatterWay way>
      {onlyPoint && <div>{numberWithSuffix(onlyPoint)}</div>}
      {pointMoney && (
        <div>
          <span>{numberWithSuffix(pointMoney?.point)}</span>
          <span>+</span>
          <span className="way-currency">
            {currencyWithSuffix(pointMoney?.money)}
          </span>
        </div>
      )}
      {onlyMoney && <div>{currencyWithSuffix(onlyMoney)}</div>}
    </StyledFormatterWay>
  )
}

const parserBtnStatus = (info) => ({
  avail: info?.availBtn?.visible,
  remove: info?.deleteBtn?.visible,
  edit: info?.editBtn?.visible,
  seal: info?.sealBtn?.visible,
  unavail: info?.unavailBtn?.visible,
  view: info?.viewBtn?.visible,
  copy: info?.copyBtn?.visible,
})

const handleView = (apiPayload, history) => () =>
  history.push(PAGE_PATHS.gift.exchangeSetting, {
    type: settingPageType.view,
    apiPayload,
  })

const handleChangeStatus = (brandId, giftSettingId, act, updateDone) => () => {
  changeStatus(brandId, giftSettingId, { act }).then(() => updateDone())
}

function AvailableSection({ cell, params, callback }) {
  const { brandId, giftSettingId, apiPayload } = params
  const { history, updateDone } = callback
  const { view, unavail } = parserBtnStatus(cell)

  return (
    <StyledFormatterSetting setting>
      {view && (
        <IconButton
          onClick={handleView(
            { brandId, giftSettingId, ...apiPayload },
            history
          )}
          tooltip="檢視"
          type="view"
        />
      )}
      {unavail && (
        <PermissionUpdateIcon
          onClick={handleChangeStatus(
            brandId,
            giftSettingId,
            'unavail',
            updateDone
          )}
          color={colorObj.lightGray}
          tooltip="下架"
          type="download"
        />
      )}
    </StyledFormatterSetting>
  )
}

function ScheduledSection({ cell, params, callback }) {
  const { brandId, giftSettingId, apiPayload } = params
  const { history, updateDone } = callback
  const { view, unavail } = parserBtnStatus(cell)

  return (
    <StyledFormatterSetting setting>
      {view && (
        <IconButton
          onClick={handleView(
            { brandId, giftSettingId, ...apiPayload },
            history
          )}
          tooltip="檢視"
          type="view"
        />
      )}
      {unavail && (
        <PermissionUpdateIcon
          onClick={handleChangeStatus(
            brandId,
            giftSettingId,
            'unavail',
            updateDone
          )}
          color={colorObj.lightGray}
          tooltip="下架"
          type="download"
        />
      )}
    </StyledFormatterSetting>
  )
}

function SealedSection({ cell, params, callback }) {
  const { brandId, giftSettingId, apiPayload } = params
  const { history } = callback
  const { view } = parserBtnStatus(cell)

  return (
    <StyledFormatter setting>
      {view && (
        <IconButton
          onClick={handleView(
            { brandId, giftSettingId, ...apiPayload },
            history
          )}
          tooltip="檢視"
          type="view"
        />
      )}
    </StyledFormatter>
  )
}

function UnavailableSection({ cell, params, callback }) {
  const { brandId, giftSettingId, apiPayload } = params
  const { history, updateDone, openModal } = callback

  const [isCopyModalShow, setIsCopyModalShow] = useState(false)
  const [copyCount, setCopyCount] = useState(1)

  const handleAvail = handleChangeStatus(
    brandId,
    giftSettingId,
    'avail',
    updateDone
  )

  const handleEdit = () =>
    history.push(PAGE_PATHS.gift.exchangeSetting, {
      type: settingPageType.edit,
      apiPayload: { brandId, giftSettingId, ...apiPayload },
    })

  const handleDelete = () =>
    deleteEvent(brandId, giftSettingId).then(() => updateDone())

  const handleSeal = () => openModal({ brandId, giftSettingId })

  const openCopyModal = () => setIsCopyModalShow(true)

  const handleCopy = () =>
    copyEvent({ brandId, giftSettingId, copyCount }).then(() => updateDone())

  const handleDropdownClick = (type) => {
    const fnEventMap = {
      [tableFnType.edit]: handleEdit,
      [tableFnType.copy]: openCopyModal,
      [tableFnType.remove]: handleDelete,
      [tableFnType.seal]: handleSeal,
    }

    if (fnEventMap[type]) fnEventMap[type]()
  }

  // 得到功能按鈕的在後端回傳的 visible 是 true or false (該功能是否能作動)
  const {
    avail: isAvailCapable,
    seal: isSealCapable,
    remove: isRemoveCapable,
    edit: isEditCapable,
    copy: isCopyCapable,
  } = parserBtnStatus(cell)

  const isUpdateAllowed = useSelector(isPermissionAllowed(UPDATE))
  const isDeleteAllowed = useSelector(isPermissionAllowed(DELETE))
  const isCreateAllowed = useSelector(isPermissionAllowed(CREATE))

  const dropdownData = useMemo(
    () => [
      {
        icon: <IconEdit className="unavailableIcon" />,
        text: <span>編輯</span>,
        isVisible: isEditCapable && isUpdateAllowed,
        type: tableFnType.edit,
      },
      {
        icon: <IconCopy className="unavailableIcon" />,
        text: <span>複製</span>,
        isVisible: isCopyCapable && isCreateAllowed,
        type: tableFnType.copy,
      },
      {
        icon: <IconDelete className="unavailableIcon" />,
        text: <span>刪除</span>,
        isVisible: isRemoveCapable && isDeleteAllowed,
        type: tableFnType.remove,
      },
      {
        icon: <IconSeal className="unavailableIcon" />,
        text: <span>封存</span>,
        isVisible: isSealCapable && isUpdateAllowed,
        type: tableFnType.seal,
      },
    ],
    [
      isEditCapable,
      isUpdateAllowed,
      isCopyCapable,
      isCreateAllowed,
      isRemoveCapable,
      isDeleteAllowed,
      isSealCapable,
    ]
  )

  const menuItems = dropdownData
    .filter(({ isVisible: isButtonVisible }) => isButtonVisible)
    .map((item) => ({
      text: (
        <>
          {item.icon}
          {item.text}
        </>
      ),
      dropdownItemOption: {
        eventKey: item.type,
        onSelect: () => handleDropdownClick(item.type),
      },
    }))

  const isMenuItemsExist = menuItems.length > 0

  return (
    <>
      <Modal
        show={isCopyModalShow}
        onClose={() => setIsCopyModalShow(false)}
        onConfirm={handleCopy}
        titleText="複製數量"
      >
        <Select
          dropdownToggleOption={{ className: 'w-100 text-center' }}
          optionItems={quantityOptions}
          selectedValue={copyCount}
          onChange={(e, value) => setCopyCount(value)}
        />
      </Modal>
      <FormatterFnWrapper flexEnd>
        {isAvailCapable && isUpdateAllowed && (
          <PermissionActivateIconButton
            onClick={() => handleAvail()}
            tooltip="上架"
            type="upload"
          />
        )}
        {isMenuItemsExist && (
          <Dropdown
            className="formatterFnBtn"
            framed
            size="sm"
            html={<IconSetting />}
            menuItems={menuItems}
          />
        )}
      </FormatterFnWrapper>
    </>
  )
}

const formatterSetting = (cell, row, ...props) => {
  const [, extraData] = props
  const { type, brandId, history, updateDone, openModal, apiPayload } =
    extraData
  const params = {
    brandId,
    giftSettingId: row?.giftSettingId,
    apiPayload,
  }
  const callback = {
    history,
    updateDone,
    openModal,
  }

  switch (type) {
    case tabStatus.available:
      return (
        <AvailableSection cell={cell} params={params} callback={callback} />
      )
    case tabStatus.scheduled:
      return (
        <ScheduledSection cell={cell} params={params} callback={callback} />
      )
    case tabStatus.sealed:
      return <SealedSection cell={cell} params={params} callback={callback} />
    case tabStatus.unavailable:
      return (
        <UnavailableSection cell={cell} params={params} callback={callback} />
      )
    default:
      return null
  }
}

export {
  Caption,
  formatterType,
  formatterInfo,
  formatterObject,
  formatterDate,
  formatterAmount,
  formatterWay,
  formatterSetting,
}

Caption.propTypes = {
  paginationProps: PropTypes.shape({
    totalSize: PropTypes.number,
    sizePerPage: PropTypes.number,
  }),

  apiPayload: PropTypes.shape({
    sf: PropTypes.string,
    so: PropTypes.string,
  }),
  setApiPayload: PropTypes.func,
}

Caption.defaultProps = {
  paginationProps: {
    totalSize: 0,
    sizePerPage: 0,
  },
  apiPayload: {
    sf: '',
    so: '',
  },
  setApiPayload: () => {},
}
