import DOMPurify from 'dompurify'
import format from 'date-fns/format'
import { toast } from 'react-hot-toast'
import { PAGE_PATHS } from 'constant'

function getReloadRedirectUrl(location) {
  const url = new URL(location)
  const path = url.pathname

  // 檢查路徑為 /edit+數字字母任意長度組合，主要使用於會員名單、帳號管理模組
  const editRegex = /\/edit\/[\w]+/
  const editMatch = path.match(editRegex)

  if (editMatch) {
    let newPath = path.replace(editMatch, '')

    // 如果路径為 /account-management-content，將其轉換成 /account-management
    if (newPath === '/account-management-content') {
      newPath = newPath.replace('-content', '')
    }

    return url.origin + newPath
  }

  // 處理其他路徑詳細頁回列表頁
  const redirections = {
    [PAGE_PATHS.activitiesCode.productActivityEdit]:
      PAGE_PATHS.activitiesCode.productActivity,
    [PAGE_PATHS.activitiesCode.productActivityNew]:
      PAGE_PATHS.activitiesCode.productActivity,
    [PAGE_PATHS.activitiesCode.qrcodeActivityContent]:
      PAGE_PATHS.activitiesCode.qrcodeActivity,
    [PAGE_PATHS.gift.exchangeSetting]: PAGE_PATHS.gift.exchange,
    [PAGE_PATHS.gift.exclusiveSetting]: PAGE_PATHS.gift.exclusive,
  }

  if (redirections[path]) {
    return url.origin + redirections[path]
  }

  return location
}

/* localstorage 獲得、設定、移除 */
function getLocalStorage(name) {
  return localStorage.getItem(name)
}
function setLocalStorage(name, value) {
  localStorage.setItem(name, value)
}
function clearLocalStorage(name) {
  localStorage.removeItem(name)
}
function clearAllLocalStorage() {
  localStorage.clear()
}
function getSessionStorage(name) {
  return sessionStorage.getItem(name)
}
function setSessionStorage(name, value) {
  sessionStorage.setItem(name, value)
}
function clearSessionStorage(name) {
  sessionStorage.removeItem(name)
}
function clearAllSessionStorage() {
  sessionStorage.clear()
}

/* 資料型態轉換 */
function dataTypeTranslate(importData, exportType) {
  let result = importData
  if (importData !== null && importData !== undefined) {
    switch (exportType) {
      case 'number':
        result = parseFloat(importData)
        break
      case 'null':
        result = null
        break
      default:
        break
    }
  }
  return result
}

/* 處理XSS字串轉DOM */
function handleXSS(dangerStr) {
  const cleanStr = DOMPurify.sanitize(dangerStr, {
    USE_PROFILES: { html: true },
    FORBID_TAGS: ['style'],
  })
  return cleanStr
}

/* 檢查是否為JSON */
function isJSON(string) {
  if (typeof string !== 'string') {
    string = JSON.stringify(string)
  }
  try {
    JSON.parse(string)
  } catch (e) {
    return false
  }
  return true
}

/* toast 相關 */
function removeToast(toastId) {
  toast.remove(toastId)
}
/* toast error時的jsx結構----待修改 
(這邊暫時放在此common.js作為一個共用function，
  未來有時間應該改為components裡的Element元件) 
*/
function toastErrorContent(status, msg, toastId, afterDismissFn) {
  const text =
    status !== undefined ? `錯誤代碼： ${status}，${msg}` : '執行錯誤'
  // 關閉toast
  const toastClose = (toastId) => {
    toast.dismiss(toastId)
    setTimeout(() => {
      toast.remove(toastId)
      if (typeof afterDismissFn === 'function') {
        afterDismissFn()
      }
    }, 300)
  }
  return (
    <div>
      <span>{text}</span>
      <span
        className="toast-error-dismiss cursor-pointer ml-2 fz13"
        onClick={() => toastClose(toastId)}
      >
        關閉
      </span>
    </div>
  )
}

function toastErrorText(text, toastId) {
  return (
    <div>
      {text}
      <span
        className="toast-error-dismiss cursor-pointer ml-2 fz13"
        onClick={() => toast.dismiss(toastId)}
      >
        關閉
      </span>
    </div>
  )
}

function debounce(func, wait) {
  let timer

  return function () {
    const context = this
    const args = arguments

    clearTimeout(timer)
    timer = setTimeout(function () {
      func.apply(context, args)
    }, wait)
  }
}

/* 獲得barcode的格式，預設帶CODE128(傳入的format若格式不支援，則仍使用預設的CODE128) */
function getBarcodeFormat(format) {
  // jsbarcode套件，支援的barcode格式
  const supportedBarcodes = [
    'CODE128',
    'EAN13',
    'EAN8',
    'EAN5',
    'EAN2',
    'UPC', // 等於UPC-A (給予11碼，第12碼會自動算出)
    'UPCE',
    'CODE39',
    'ITF14',
    'ITF',
    'MSI',
    'MSI10',
    'MSI11',
    'MSI1010',
    'MSI1110',
    'PHARMACODE',
  ]
  for (const barcodeFormat of supportedBarcodes) {
    if (format === barcodeFormat) {
      return format
    }
  }
  return supportedBarcodes[0]
}

function downloadCsv({
  fileName = '預設檔名',
  url = '',
  data = '預設標題\r\n \r\n',
  isAppendTime = true,
} = {}) {
  const now = new Date()
  const time = format(now, 'yyyyMMddHHmm')
  // 防止中文變亂碼，所以前綴加上 \ufeff
  const href =
    url || `data:text/csv;charset=utf-8,\ufeff${encodeURIComponent(data)}`
  const downloadFileName = isAppendTime
    ? `${fileName}_${time}.csv`
    : `${fileName}.csv`
  const a = document.createElement('a')
  a.href = href
  a.download = downloadFileName
  document.body.appendChild(a)
  a.click()
  a.remove()
}

// 讓物件根據所需要的排序轉陣列形式輸出
function objectSort(originObject, sortArray = []) {
  const arr = []
  if (originObject?.constructor === Object) {
    const objectLength = Object.keys(originObject).length
    for (let i = 0; i < objectLength; i++) {
      for (const [sortIndex, objectKey] of sortArray.entries()) {
        if (i === sortIndex) {
          arr.push({
            [objectKey]: originObject[objectKey],
          })
        }
      }
    }
  }
  return arr
}

// 獲得隨機的id
function getRandomId() {
  return Math.random().toString(36).substring(2)
}

// 增加 id 到物件資料內供 react key 使用
function addId(data) {
  return data.map((item, index) => {
    return {
      ...item,
      id: index,
    }
  })
}

// 深拷貝
/**
 * @param {Object|Array} source - 要拷貝的物件或陣列。
 * @returns {Object|Array} - 深拷貝後的物件或陣列。
 */
function deepCopy(source) {
  if (typeof source !== 'object' || source === null) {
    return source
  }
  const target = Array.isArray(source) ? [] : {}
  for (const key in source) {
    target[key] = deepCopy(source[key])
  }

  return target
}

/**
 *
 * @param {string} regPath
 * @param {string} currentPath
 * @description 判斷路徑是否符合動態路徑
 * @returns {boolean}
 * ＠example regPath = '/member' currentPath = '/member/edit' return true
 * ＠example regPath = '/member' currentPath = '/member' return true
 * @example regPath = '/member/edit' currentPath = '/member/edit/123' return false
 * @example regPath = '/member/edit' currentPath = '/member/edit' return true
 */
const dynamicPathRegex = (regPath, currentPath) => {
  const dynamicPath = `^${regPath}(/[^/]+)?$`
  const regex = new RegExp(`^${dynamicPath}$`)
  return regex.test(currentPath)
}

/**
 *
 * @param {*} e
 * @description 將存取的資料寫入localstorage(為了給另開新分頁的頁面做判斷取值)
 * @returns null
 */
const setHomeDataStorage = (e) => {
  const sessionStorageKeyboardEventKey = getSessionStorage('keyboardEventKey')
  if (
    // 按下滑鼠右鍵
    e.button === 2 ||
    // 控制鍵盤command 或 ctrl ， + 滑鼠左鍵，Meta為mac的command鍵判斷
    sessionStorageKeyboardEventKey === 'Meta' ||
    sessionStorageKeyboardEventKey === 'Control' ||
    e.button === 0
  ) {
    const sessionStorageHomeData = getSessionStorage('homeData')
    setLocalStorage('homeData', sessionStorageHomeData)
  }
}

/**
 *
 * @param {Array} items - 目標值。
 * @returns {Object} 屬性 selected 有值的物件。
 */
function findSelectedItem(items) {
  if (!Array.isArray(items)) return null
  return items.find((item) => item.selected) || null
}

/**
 * @param {any} value - 任何類型的值。
 * @returns {bool} 傳入的值是否為有值的陣列。
 */
function isNonEmptyArray(value) {
  if (!Array.isArray(value)) return false
  return value.length > 0
}

/**
 * 將字串轉換為大駝峰
 * @param {string} input - 要進行轉換的字串。
 * @returns {string} 轉換變為大駝峰的字串。
 */
function toPascalCase(input) {
  // 刪除特殊字符和多個連續的空格和底線
  const cleanedInput = input.replace(/[^\w\s-]/g, '').replace(/[-_\s]+/g, ' ')

  // 分割字符串成單詞
  const words = cleanedInput.split(' ')

  // 將每個單詞的首字母大寫，其餘小寫
  const pascalCasedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  )

  // 組合單詞成一個字符串
  const pascalCasedString = pascalCasedWords.join('')

  return pascalCasedString
}

/**
 * 欄位 value 型別轉換 (欄位 value 都以字串取值)
 * @param {string} type - 所要轉換的類型。
 * @param {string} value - 欄位字串。
 * @returns {string|number|bool} 類型轉換後的值。
 */
const convertToType = (type, value) => {
  const typeMapping = {
    number: value ? Number(value) : value,
    boolean: !!(value && value === 'true'),
  }
  const convertedValue = typeMapping[type] ?? value
  return convertedValue
}

/**
 * 限制欄位只能輸入數字
 * @param {object} string - input 的事件物件。
 */
const sanitizeNonNumberStr = (string) => {
  return string.replace(/\D+/g, '')
}

/**
 * 將物件的字串屬性去除前後空白
 * @param {object} obj - 物件
 * @returns {object} 字串空白都刪除後的物件。
 */
const trimFormString = (obj) => {
  // 不是物件的話直接回傳
  if (typeof obj !== 'object') return obj

  if (Array.isArray(obj)) return obj

  // 建立新的物件來存放處理後的值
  const trimmedObject = Object.keys(obj).reduce((acc, key) => {
    // 檢查屬性是否是物件自身的屬性
    if (obj.hasOwnProperty(key)) {
      const value = obj[key]
      const trimmedValue = typeof value === 'string' ? value.trim() : value
      acc[key] = trimmedValue
    }

    return acc
  }, {})

  // 回傳處理好的新物件
  return trimmedObject
}

/**
 * 讓 input 可以在按下 Enter 時觸發其他功能，並且 lose focus
 * @param {object} e - input 的事件物件
 * @param {function} callback - 要觸發的功能
 */
function handleKeyPressEnter(e, callback) {
  if (e.key === 'Enter') {
    e.preventDefault()
    if (e.target.value.trim() === '') return
    callback()
    e.target.blur()
  }
}

/**
 * 生成在 csv 檔上的範例序號
 * @param {number} quantity - 產生的數量
 */
function createExampleNumber(quantity) {
  let result = ''
  for (let i = 1; i <= quantity; i += 1) {
    result += `QC${i.toString().padStart(5, '0')}\r\n`
  }
  return result
}

/**
 * 生成在 csv 檔上的範例網址
 * @param {number} quantity - 產生的數量
 */
function createExampleUrl(quantity) {
  let result = ''
  for (let i = 1; i <= quantity; i += 1) {
    result += `https://www.example.com/page.html?key=value${i}\r\n`
  }
  return result
}

// 產生的訊息共有七種組合
// 1. 累積消費 + 累積次數 + 單筆累消
// 2. 累積消費 + 累積次數
// 3. 累積消費 + 單筆累消
// 4. 累積次數 + 單筆累消
// 5. 累積消費
// 6. 累積次數
// 7. 單筆累消
function generateLevelInfo(stillNeed, stillCount, minSingle) {
  const isStillNeedRequired = !!stillNeed
  const isStillCountRequired = !!stillCount
  const isMinSingleRequired = !!minSingle

  const conditions = [
    {
      check: isStillNeedRequired && isStillCountRequired && isMinSingleRequired,
      message: `累積消費再達 ${stillNeed}且 ${stillCount}，或 單筆消費滿 ${minSingle}`,
    },
    {
      check: isStillNeedRequired && isStillCountRequired,
      message: `累積消費再達 ${stillNeed}且 ${stillCount}`,
    },
    {
      check: isStillNeedRequired && isMinSingleRequired,
      message: `累積消費再達 ${stillNeed}，或 單筆消費滿 ${minSingle}`,
    },
    {
      check: isStillCountRequired && isMinSingleRequired,
      message: `累積次數滿 ${stillCount}，或 單筆消費滿 ${minSingle}`,
    },
    { check: isStillNeedRequired, message: `累積消費再達 ${stillNeed}` },
    { check: isStillCountRequired, message: `累積消費再達 ${stillCount}` },
    { check: isMinSingleRequired, message: `單筆消費滿 ${minSingle}` },
  ]
  for (const condition of conditions) {
    if (condition.check) return condition.message
  }
  return ''
}

export {
  getLocalStorage,
  setLocalStorage,
  clearLocalStorage,
  clearAllLocalStorage,
  getSessionStorage,
  setSessionStorage,
  clearSessionStorage,
  clearAllSessionStorage,
  dataTypeTranslate,
  handleXSS,
  isJSON,
  removeToast,
  toastErrorContent,
  toastErrorText,
  debounce,
  getBarcodeFormat,
  downloadCsv,
  objectSort,
  getRandomId,
  addId,
  deepCopy,
  dynamicPathRegex,
  setHomeDataStorage,
  getReloadRedirectUrl,
  findSelectedItem,
  isNonEmptyArray,
  toPascalCase,
  convertToType,
  sanitizeNonNumberStr,
  trimFormString,
  handleKeyPressEnter,
  createExampleNumber,
  createExampleUrl,
  generateLevelInfo,
}
