import { ReactComponent as IconCalendar } from 'assets/images/icon_calendar.svg'
import { colorObj } from 'assets/styles/Variable/Color'

// 日期欄位的prependContenet設定
const datePrependContentSetting = () => {
  return {
    prependContent: <IconCalendar />,
    prependContentBgColor: 'transparent',
    prependContentHaveBorder: false,
    prependContentPadding: '10px 0 10px 10px',
    prependContentColor: colorObj.gray,
  }
}

// 所有欄位的FieldTypeSwitch元件的屬性設定值
const allFieldTypeSwitchProp = {
  name: {
    type: 'text',
    formControlOption: {
      name: 'name',
      required: true,
      placeholder: '上限 50 字',
      maxLength: 50,
      autoComplete: 'off',
    },
  },
  validateFromNow: {
    type: 'radio',
    formControlOption: {
      className: 'mb-3',
      name: 'validateStart',
    },
    options: [
      {
        name: '立即開始',
        code: 'now',
      },
    ],
  },
  validateFromStart: {
    type: 'radio',
    containerClass: 'w-auto d-flex',
    formControlOption: {
      name: 'validateStart',
    },
    inline: true,
    options: [
      {
        name: '起始日',
        code: 'startDate',
      },
    ],
  },
  validateFromStartDate: {
    type: 'date',
    formControlOption: {
      name: 'startDate',
      readOnly: true,
      disabled: true,
      placeholder: '起始日期',
    },
    min: new Date(),
    inputOption: datePrependContentSetting(),
  },
  validateEndForever: {
    type: 'radio',
    className: 'mb-3',
    formControlOption: {
      name: 'validateFinal',
    },
    options: [
      {
        name: '永久',
        code: 'forever',
      },
    ],
  },
  validateEndFinal: {
    type: 'radio',
    containerClass: 'w-auto d-flex',
    formControlOption: {
      name: 'validateFinal',
      inline: true,
    },
    inline: true,
    options: [
      {
        name: '結束日',
        code: 'endDate',
      },
    ],
  },
  validateEndFinalDate: {
    type: 'date',
    formControlOption: {
      name: 'endDate',
      disabled: true,
      placeholder: '結束日期',
    },
    min: new Date(),
    inputOption: datePrependContentSetting(),
  },
  point: {
    type: 'text',
    formControlOption: {
      name: 'point',
      autoComplete: 'off',
    },
    inputOption: {
      appendContent: '點',
      appendContentColor: '#000',
      appendContentBgColor: '#fff',
      appendContentPadding: '0 4px 0 0',
      appendContentHaveBorder: false,
      isFocusCocatAppend: true,
    },
  },
  // 系統計算 radio
  systemCalculated: {
    type: 'radio',
    className: 'mb-3',
    formControlOption: {
      name: 'expirationType', // 點數效期
      value: '0', // 預設要打勾
    },
    options: [
      {
        name: '依系統設定',
        code: '0',
      },
    ],
  },
  // 特定的到期日 radio
  fixedDate: {
    type: 'radio',
    containerClass: 'w-auto d-flex mb-3',
    formControlOption: {
      name: 'expirationType', // 點數效期
      inline: true,
    },
    inline: true,
    options: [
      {
        name: '指定日期',
        code: '1',
      },
    ],
  },
  expirationDate: {
    type: 'date',
    containerClass: 'mb-3',
    formControlOption: {
      name: 'expirationDate', // 點數效期
      disabled: true,
      placeholder: '到期日',
    },
    min: new Date(),
    inputOption: datePrependContentSetting(),
  },
  // 獲得點數後 N 天到期 radio
  validForDays: {
    type: 'radio',
    containerClass: 'w-auto d-flex',
    formControlOption: {
      name: 'expirationType', // 點數效期
      inline: true,
    },
    inline: true,
    options: [
      {
        name: '獲得點數後',
        code: '2',
      },
    ],
  },
  validDays: {
    type: 'text',
    formControlOption: {
      name: 'validDays',
      required: false,
      placeholder: '上限 365 天',
      autoComplete: 'off',
      disabled: true,
    },
  },
  liffTitle: {
    formControlOption: {
      type: 'text',
      name: 'liffTitle',
      maxLength: 30,
      placeholder: '選填，上限 30 字',
    },
  },
  liffCaption: {
    formControlOption: {
      type: 'text',
      name: 'liffDescription',
      maxLength: 50,
      placeholder: '選填，上限 50 字',
    },
  },
}

export { allFieldTypeSwitchProp, datePrependContentSetting }
