import { fetchData } from 'api/ApiRoute'
import { commonFetchProps, showToast, parserUrl } from 'api/ApiCommon'

const gift = {
  default: 'setting/brand/{brandId}/gifts',
  changeStatus: 'setting/brand/{brandId}/gifts/{giftSettingId}/changestatus',
  info: 'setting/brand/{brandId}/gifts/{giftSettingId}',
  copy: 'setting/brand/{brandId}/gifts/{giftSettingId}/copy?copyCount={copyCount}',
  memberList: 'setting/brand/{brandId}/membership/levelitems',
  designatedList: 'setting/brand/{brandId}/vipgifts/designated-list',
  exchangeCodeRules:
    'setting/brand/{brandId}/gifts/{giftSettingId}/exchangecode/rule',
  exchangeCodeSystemGenerated:
    'setting/brand/{brandId}/gifts/{giftSettingId}/exchangecode/systemgenerated',
  exchangeCodeImport:
    'setting/brand/{brandId}/gifts/{giftSettingId}/exchangecode/import',
  exchangeCodeExport:
    'setting/brand/{brandId}/gifts/{giftSettingId}/exchangecode/export',
  exchangeCode: 'setting/brand/{brandId}/gifts/{giftSettingId}/exchangecode',
  exchangeCodeBatch:
    'setting/brand/{brandId}/gifts/{giftSettingId}/exchangecode/batch',
  exchangeCodeDetail:
    'setting/brand/{brandId}/gifts/{giftSettingId}/exchangecode/{giftExchangeCodeId}',
  memberOutline: 'member/{memberId}/outline',
  exchangedRecord:
    'setting/brand/{brandId}/gifts/{giftSettingId}/exchangerecord',
  exchangedRecordDetail:
    'setting/brand/{brandId}/gifts/{giftSettingId}/exchangerecord/{giftRecordObjId}',
  giftExchangeRecord: 'member/gifts/giftexchange',
  giftExchangeRecordDetail: 'member/gifts/giftexchange/{giftRecordObjId}',
  vipGiftExchangeRecord: 'member/gifts/vipgiftexchange',
  vipGiftExchangeRecordDetail: 'member/gifts/vipgiftexchange/{giftRecordObjId}',
  vipGifts: 'setting/brand/{brandId}/vipgifts',
  vipGiftInfo: 'setting/brand/{brandId}/vipgifts/{vipGiftSettingId}',
  vipGiftChangeStatus:
    'setting/brand/{brandId}/vipgifts/{vipGiftSettingId}/changestatus',
  vipGiftCopy: 'setting/brand/{brandId}/vipgifts/{vipGiftSettingId}/copy',
  vipExchangeCode:
    'setting/brand/{brandId}/vipgifts/{vipGiftSettingId}/vipexchangecode',
  vipExchangeCodeDetail:
    'setting/brand/{brandId}/vipgifts/{vipGiftSettingId}/vipexchangecode/{vipGiftExchangeCodeId}',
  vipExchangeCodeImport:
    'setting/brand/{brandId}/vipgifts/{vipGiftSettingId}/vipexchangecode/import',
  vipExchangeCodeBatch:
    'setting/brand/{brandId}/vipgifts/{vipGiftSettingId}/vipexchangecode/batch',
  vipExchangeCodeRule:
    'setting/brand/{brandId}/vipgifts/{vipGiftSettingId}/vipexchangecode/rule',
  vipExchangeCodeRecord:
    'setting/brand/{brandId}/vipgifts/{vipGiftSettingId}/vipexchangerecord',
  vipExchangeCodeRecordDetail:
    'setting/brand/{brandId}/vipgifts/{vipGiftSettingId}/vipexchangerecord/{vipGiftRecordObjId}',
  vipExchangeCodeLink: 'setting/brand/{brandId}/vipgifts/canbelinked',
  vipExchangeCodeUnlink:
    'setting/brand/{brandId}/vipgifts/{vipGiftSettingId}/unlink',
  giftDelivery: 'member/gifts/gift-delivery',
  giftDeliveryDetail: 'member/gifts/gift-delivery/{giftRecordObjId}',
  patchGiftDelivery: 'member/gifts/gift-delivery/{giftRecordObjId}',
  notificationGift: 'account/notification/gift',
  giftDeliveryExport: 'member/gifts/gift-delivery/export',
  productItemImport: 'member/gifts/gift-delivery/import',
  validateCodeSetting: 'member/gifts/validatecodesetting',
  vipGiftReimburse: 'member/gifts/vipgiftreimburse',
  vipGiftReimburseDetail: 'member/gifts/vipgiftreimburse/{giftRecordObjId}',
  giftReimburse: 'member/gifts/giftreimburse',
  giftReimburseDetail: 'member/gifts/giftreimburse/{giftRecordObjId}',
}

const handleError400 = (rsp, isFieldHandle) => {
  // isFieldHandle 表示欄位會紅字處理，不用再跳toast
  if (rsp.status === 400 && rsp?.data?.msg && !isFieldHandle) showToast(rsp)
  return Promise.reject(rsp.data ?? rsp)
}

const getGiftList = (brandId, payload) => {
  return fetchData(
    parserUrl(gift.default, { brandId }, payload),
    'GET',
    null,
    commonFetchProps
  ).catch(handleError400)
}

const changeStatus = (brandId, giftSettingId, payload) => {
  return fetchData(
    parserUrl(gift.changeStatus, { brandId, giftSettingId }, payload),
    'PATCH',
    null,
    commonFetchProps
  ).catch(handleError400)
}

const deleteEvent = (brandId, giftSettingId) => {
  return fetchData(
    parserUrl(gift.info, { brandId, giftSettingId }),
    'DELETE',
    null,
    commonFetchProps
  ).catch(handleError400)
}

const getEvent = (brandId, giftSettingId) => {
  return fetchData(
    parserUrl(gift.info, { brandId, giftSettingId }),
    'GET',
    null,
    commonFetchProps
  ).catch(handleError400)
}

// 複製 好禮贈送 - 贈品兌換
const copyEvent = ({ brandId, giftSettingId, copyCount }) => {
  return fetchData(
    parserUrl(gift.copy, { brandId, giftSettingId, copyCount }),
    'POST',
    null
  ).catch(handleError400)
}

const updateEvent = (brandId, giftSettingId, body) => {
  return fetchData(
    parserUrl(gift.info, { brandId, giftSettingId }),
    'PATCH',
    body
  ).catch(handleError400)
}

const addEvent = (brandId, body) => {
  return fetchData(parserUrl(gift.default, { brandId }), 'POST', body, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }).catch(handleError400)
}

const getMemberList = (brandId) => {
  return fetchData(
    parserUrl(gift.memberList, { brandId }),
    'GET',
    null,
    commonFetchProps
  ).catch(handleError400)
}

const getDesignatedList = (brandId, payload) => {
  return fetchData(
    parserUrl(gift.designatedList, { brandId }, payload),
    'GET',
    null,
    commonFetchProps
  ).catch(handleError400)
}

const patchExchangeCodeRules = async (brandId, giftSettingId, body) => {
  try {
    const rsp = await fetchData(
      parserUrl(gift.exchangeCodeRules, { brandId, giftSettingId }),
      'PATCH',
      body
    )
    return rsp.data
  } catch (err) {
    return handleError400(err)
  }
}

const generateSystemExchangeCode = (
  brandId,
  giftSettingId,
  body,
  cancelToken
) => {
  return fetchData(
    parserUrl(gift.exchangeCodeSystemGenerated, { brandId, giftSettingId }),
    'POST',
    body,
    cancelToken
  ).catch(handleError400)
}
const importExchangeCode = (brandId, giftSettingId, body, cancelToken) => {
  return fetchData(
    parserUrl(gift.exchangeCodeImport, { brandId, giftSettingId }),
    'POST',
    body,
    cancelToken
  ).catch(handleError400)
}
const exportExchangeCode = (brandId, giftSettingId, payload) => {
  return fetchData(
    parserUrl(gift.exchangeCodeExport, { brandId, giftSettingId }, payload),
    'GET',
    null,
    commonFetchProps
  ).catch(handleError400)
}
const getExchangeCodeList = (brandId, giftSettingId, payload) => {
  return fetchData(
    parserUrl(gift.exchangeCode, { brandId, giftSettingId }, payload),
    'GET',
    null,
    commonFetchProps
  ).catch(handleError400)
}

const getExchangeCodeDetail = ({
  brandId,
  giftSettingId,
  giftExchangeCodeId,
}) => {
  return fetchData(
    parserUrl(
      gift.exchangeCodeDetail,
      { brandId, giftSettingId, giftExchangeCodeId },
      null
    ),
    'GET',
    null,
    commonFetchProps
  )
    .then((rsp) => rsp.data)
    .catch(handleError400)
}

const addExchangeCode = (brandId, giftSettingId, body) => {
  return fetchData(
    parserUrl(gift.exchangeCode, { brandId, giftSettingId }),
    'POST',
    body
  ).catch(handleError400)
}
const switchExchangeCodeType = (brandId, giftSettingId, body) => {
  return fetchData(
    parserUrl(gift.exchangeCode, { brandId, giftSettingId }),
    'DELETE',
    body
  ).catch(handleError400)
}
const batchDeleteExchangeCode = (brandId, giftSettingId, body) => {
  return fetchData(
    parserUrl(gift.exchangeCodeBatch, { brandId, giftSettingId }),
    'DELETE',
    body
  ).catch(handleError400)
}

const getExchangedRecord = (brandId, giftSettingId, payload) => {
  return fetchData(
    parserUrl(gift.exchangedRecord, { brandId, giftSettingId }, payload),
    'GET',
    null,
    commonFetchProps
  ).catch(handleError400)
}

const getExchangeRecordDetail = ({
  brandId,
  giftSettingId,
  giftRecordObjId,
}) => {
  return fetchData(
    parserUrl(gift.exchangedRecordDetail, {
      brandId,
      giftSettingId,
      giftRecordObjId,
    }),
    'GET',
    null,
    commonFetchProps
  )
    .then((rsp) => rsp.data)
    .catch(handleError400)
}

const getMemberOutline = (memberId, payload) => {
  return fetchData(
    parserUrl(gift.memberOutline, { memberId }, payload),
    'GET',
    null,
    commonFetchProps
  )
    .then((rsp) => rsp.data)
    .catch(handleError400)
}

const getGiftExchangeRecord = (payload) => {
  return fetchData(
    parserUrl(gift.giftExchangeRecord, {}, payload),
    'GET',
    null,
    commonFetchProps
  )
    .then((rsp) => rsp.data)
    .catch(handleError400)
}
const getGiftExchangeRecordDetail = ({ brandId, giftRecordObjId }) => {
  return fetchData(
    parserUrl(gift.giftExchangeRecordDetail, { giftRecordObjId }, { brandId }),
    'GET',
    null,
    commonFetchProps
  )
    .then((rsp) => rsp.data)
    .catch(handleError400)
}
const getVipGiftExchangeRecord = (payload) => {
  return fetchData(
    parserUrl(gift.vipGiftExchangeRecord, {}, payload),
    'GET',
    null,
    commonFetchProps
  )
    .then((rsp) => rsp.data)
    .catch(handleError400)
}

const getVipGiftExchangeRecordDetail = ({ brandId, giftRecordObjId }) => {
  return fetchData(
    parserUrl(
      gift.vipGiftExchangeRecordDetail,
      { giftRecordObjId },
      { brandId }
    ),
    'GET',
    null,
    commonFetchProps
  )
    .then((rsp) => rsp.data)
    .catch(handleError400)
}

const getVipGiftList = (brandId, payload) => {
  return fetchData(
    parserUrl(gift.vipGifts, { brandId }, payload),
    'GET',
    null,
    commonFetchProps
  ).catch(handleError400)
}
const addVipGift = (brandId, body) => {
  return fetchData(parserUrl(gift.vipGifts, { brandId }), 'POST', body).catch(
    handleError400
  )
}
const getVipGiftInfo = (brandId, giftSettingId) => {
  return fetchData(
    parserUrl(gift.vipGiftInfo, { brandId, vipGiftSettingId: giftSettingId }),
    'GET',
    null,
    commonFetchProps
  ).catch(handleError400)
}
const updateVipGiftInfo = (brandId, giftSettingId, body) => {
  return fetchData(
    parserUrl(gift.vipGiftInfo, { brandId, vipGiftSettingId: giftSettingId }),
    'PATCH',
    body
  ).catch(handleError400)
}
const deleteVipGiftInfo = (brandId, giftSettingId) => {
  return fetchData(
    parserUrl(gift.vipGiftInfo, { brandId, vipGiftSettingId: giftSettingId }),
    'DELETE',
    null,
    commonFetchProps
  ).catch(handleError400)
}
const changeVipGiftStatus = (brandId, giftSettingId, payload) => {
  return fetchData(
    parserUrl(
      gift.vipGiftChangeStatus,
      { brandId, vipGiftSettingId: giftSettingId },
      payload
    ),
    'PATCH',
    null,
    commonFetchProps
  ).catch(handleError400)
}
// 複製 好禮贈送 - 專屬好禮
const copyVipGiftInfo = (brandId, giftSettingId) => {
  return fetchData(
    parserUrl(gift.vipGiftCopy, { brandId, vipGiftSettingId: giftSettingId }),
    'POST',
    null
  ).catch(handleError400)
}

const getVipExchangeCodeList = (brandId, giftSettingId, payload) => {
  return fetchData(
    parserUrl(
      gift.vipExchangeCode,
      { brandId, vipGiftSettingId: giftSettingId },
      payload
    ),
    'GET',
    null,
    commonFetchProps
  ).catch(handleError400)
}

const getVipExchangeCodeDetail = ({
  brandId,
  vipGiftSettingId,
  vipGiftExchangeCodeId,
}) => {
  return fetchData(
    parserUrl(gift.vipExchangeCodeDetail, {
      brandId,
      vipGiftSettingId,
      vipGiftExchangeCodeId,
    }),
    'GET',
    null,
    commonFetchProps
  )
    .then((rsp) => rsp.data)
    .catch(handleError400)
}

const importVipExchangeCode = (brandId, giftSettingId, body, cancelToken) => {
  return fetchData(
    parserUrl(gift.vipExchangeCodeImport, {
      brandId,
      vipGiftSettingId: giftSettingId,
    }),
    'POST',
    body,
    cancelToken
  ).catch(handleError400)
}
const addVipExchangeCode = (brandId, giftSettingId, body) => {
  return fetchData(
    parserUrl(gift.vipExchangeCode, {
      brandId,
      vipGiftSettingId: giftSettingId,
    }),
    'POST',
    body
  ).catch((res) => handleError400(res))
}
const switchVipExchangeCodeType = (brandId, giftSettingId, body) => {
  return fetchData(
    parserUrl(gift.vipExchangeCode, {
      brandId,
      vipGiftSettingId: giftSettingId,
    }),
    'DELETE',
    body
  ).catch(handleError400)
}
const batchDeleteVipExchangeCode = (brandId, giftSettingId, body) => {
  return fetchData(
    parserUrl(gift.vipExchangeCodeBatch, {
      brandId,
      vipGiftSettingId: giftSettingId,
    }),
    'DELETE',
    body
  ).catch(handleError400)
}
const checkVipExchangeRule = (brandId, giftSettingId, body) => {
  return fetchData(
    parserUrl(gift.vipExchangeCodeRule, {
      brandId,
      vipGiftSettingId: giftSettingId,
    }),
    'PATCH',
    body
  ).catch(handleError400)
}
const getVipExchangedRecord = (brandId, giftSettingId, payload) => {
  return fetchData(
    parserUrl(
      gift.vipExchangeCodeRecord,
      { brandId, vipGiftSettingId: giftSettingId },
      payload
    ),
    'GET',
    null,
    commonFetchProps
  ).catch(handleError400)
}

const getVipExchangeRecordDetail = ({
  brandId,
  vipGiftSettingId,
  vipGiftRecordObjId,
}) => {
  return fetchData(
    parserUrl(gift.vipExchangeCodeRecordDetail, {
      brandId,
      vipGiftSettingId,
      vipGiftRecordObjId,
    }),
    'GET',
    null,
    commonFetchProps
  )
    .then((rsp) => rsp.data)
    .catch(handleError400)
}

const getVipExchangeLink = (brandId) => {
  return fetchData(
    parserUrl(gift.vipExchangeCodeLink, { brandId }),
    'GET',
    null,
    commonFetchProps
  ).catch(handleError400)
}
const vipExchangeUnlink = (brandId, giftSettingId) => {
  return fetchData(
    parserUrl(gift.vipExchangeCodeUnlink, {
      brandId,
      vipGiftSettingId: giftSettingId,
    }),
    'PATCH',
    null,
    commonFetchProps
  ).catch(handleError400)
}
const getGiftDelivery = (payload) => {
  return fetchData(
    parserUrl(gift.giftDelivery, {}, payload),
    'GET',
    null,
    commonFetchProps
  ).catch(handleError400)
}
const getGiftDeliveryDetail = ({ brandId, giftRecordObjId }) => {
  return fetchData(
    parserUrl(gift.giftDeliveryDetail, { giftRecordObjId }, { brandId }),
    'GET',
    null,
    commonFetchProps
  )
    .then((rsp) => rsp.data)
    .catch(handleError400)
}
const patchGiftDelivery = (giftRecordObjId, body) => {
  return fetchData(
    parserUrl(gift.patchGiftDelivery, {
      giftRecordObjId,
    }),
    'PATCH',
    body,
    commonFetchProps
  ).catch(handleError400)
}
const getNotificationGift = (brandId, type) => {
  return fetchData(
    parserUrl(gift.notificationGift, null, {
      brandId,
      type,
    }),
    'GET',
    null,
    commonFetchProps
  ).catch(handleError400)
}
const postNotificationGift = (body) => {
  return fetchData(
    parserUrl(gift.notificationGift),
    'POST',
    body,
    commonFetchProps
  ).catch(handleError400)
}
const getGiftDeliveryExport = (payload) => {
  return fetchData(
    parserUrl(gift.giftDeliveryExport, null, payload),
    'GET',
    null,
    commonFetchProps
  ).catch(handleError400)
}
const postImportProductItems = (body) => {
  return fetchData(parserUrl(gift.productItemImport), 'POST', body, null).catch(
    handleError400
  )
}

const getValidateCodeSetting = (payload) => {
  return fetchData(
    parserUrl(gift.validateCodeSetting, {}, payload),
    'GET',
    null,
    commonFetchProps
  )
    .then((rsp) => rsp.data)
    .catch(handleError400)
}

const getVipGiftReimburse = (payload) => {
  return fetchData(
    parserUrl(gift.vipGiftReimburse, {}, payload),
    'GET',
    null,
    commonFetchProps
  ).catch(handleError400)
}

const getVipGiftReimburseDetail = ({ brandId, giftRecordObjId }) => {
  return fetchData(
    parserUrl(gift.vipGiftReimburseDetail, { giftRecordObjId }, { brandId }),
    'GET',
    null,
    commonFetchProps
  )
    .then((rsp) => rsp.data)
    .catch(handleError400)
}

const patchVipGiftReimburse = async (body) => {
  try {
    const rsp = await fetchData(parserUrl(gift.vipGiftReimburse), 'PATCH', body)
    return rsp.data
  } catch (err) {
    return handleError400(err)
  }
}

const getGiftReimburse = (payload) => {
  return fetchData(
    parserUrl(gift.giftReimburse, {}, payload),
    'GET',
    null,
    commonFetchProps
  )
    .then((rsp) => rsp.data)
    .catch(handleError400)
}

const getGiftReimburseDetail = ({ brandId, giftRecordObjId }) => {
  return fetchData(
    parserUrl(gift.giftReimburseDetail, { giftRecordObjId }, { brandId }),
    'GET',
    null,
    commonFetchProps
  )
    .then((rsp) => rsp.data)
    .catch(handleError400)
}

const patchGiftReimburse = async (body) => {
  return fetchData(parserUrl(gift.giftReimburse), 'PATCH', body)
    .then((rsp) => rsp.data)
    .catch(handleError400)
}

export {
  getGiftList,
  copyEvent,
  deleteEvent,
  getEvent,
  updateEvent,
  addEvent,
  changeStatus,
  getMemberList,
  getDesignatedList,
  patchExchangeCodeRules,
  generateSystemExchangeCode,
  importExchangeCode,
  exportExchangeCode,
  getExchangeCodeList,
  getExchangeCodeDetail,
  addExchangeCode,
  switchExchangeCodeType,
  batchDeleteExchangeCode,
  getMemberOutline,
  getExchangedRecord,
  getExchangeRecordDetail,
  getGiftExchangeRecord,
  getGiftExchangeRecordDetail,
  getVipGiftExchangeRecord,
  getVipGiftExchangeRecordDetail,
  getVipGiftList,
  addVipGift,
  getVipGiftInfo,
  updateVipGiftInfo,
  deleteVipGiftInfo,
  changeVipGiftStatus,
  copyVipGiftInfo,
  getVipExchangeCodeList,
  getVipExchangeCodeDetail,
  importVipExchangeCode,
  addVipExchangeCode,
  switchVipExchangeCodeType,
  batchDeleteVipExchangeCode,
  checkVipExchangeRule,
  getVipExchangedRecord,
  getVipExchangeRecordDetail,
  getVipExchangeLink,
  vipExchangeUnlink,
  getGiftDelivery,
  getGiftDeliveryDetail,
  patchGiftDelivery,
  getNotificationGift,
  postNotificationGift,
  getGiftDeliveryExport,
  postImportProductItems,
  getValidateCodeSetting,
  getVipGiftReimburse,
  getVipGiftReimburseDetail,
  patchVipGiftReimburse,
  getGiftReimburse,
  getGiftReimburseDetail,
  patchGiftReimburse,
}
